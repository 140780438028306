import { isFunction } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import authFirebaseApp from "../../authFirebase";
import Form from "../../components/Form";
import firebaseApp from "../../firebase";
import { AttendeeData, Channel, Content, PaywallGlobalAccess } from "../../interfaces";
import api from "../../services/api";
import { updateAnalyticsUserDoc } from "../../services/dataHelpers";
import GlobalLoader from "../GlobalLoader";
import {
	PaywallContainer,
	PaywallErrorText,
	PaywallFormWrapper,
	PaywallHeaderImageContainer,
	PaywallImage,
} from "./Paywall.styled";

interface PaywallProps {
	channel?: Channel;
	analyticsUserId: string;
	children: React.ReactNode;
	content?: Content;
	title: string;
	eventNumber?: string;
	headerImage?: string;
	header?: React.ReactNode;
	footer?: React.ReactNode;
}

interface FormProps {
	email?: string;
	attendeeNumber?: string;
}

interface AttendeeDataContextInterface {
	attendeeData?: AttendeeData;
	paywallEnabled: boolean;
	logout: () => void;
}

export const AttendeeDataContext = React.createContext<AttendeeDataContextInterface>({
	attendeeData: undefined,
	paywallEnabled: true,
	logout: () => { },
});

const db = firebaseApp.firestore();

const Paywall: React.FC<PaywallProps> = ({
	children,
	channel,
	content,
	eventNumber,
	analyticsUserId,
	title,
	headerImage,
	header,
	footer,
}) => {
	const location = useLocation();

	const PAYWALL_ATTENDEE_NUMBER = `paywall-attendee-number-${channel ? channel.clientId : eventNumber}`;

	const attendeeNumberQuery = (queryString.parse(location.search).a || queryString.parse(location.search).A) as
		| string
		| undefined;
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [currentForm, setCurrentForm] = useState("attendee");
	const [attendeeNumber, setAttendeeNumber] = useState(
		attendeeNumberQuery || localStorage.getItem(PAYWALL_ATTENDEE_NUMBER)
	);
	const [hasPermission, setHasPermission] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [errorText, setErrorText] = useState<string>();

	const [attendeeData, setAttendeeData] = useState<AttendeeData>();

	const isMobile = useMediaQuery({ maxWidth: "600px" });
	const isMobileLandscape = useMediaQuery({ maxHeight: 600, orientation: "landscape" });

	const toggleForm = () => {
		if (currentForm === "attendee") {
			setCurrentForm("email");
		} else {
			setCurrentForm("attendee");
		}
	};

	const saveAttendeeNumber = (attendeeNumber: string) => {
		localStorage.setItem(PAYWALL_ATTENDEE_NUMBER, attendeeNumber);
		setAttendeeNumber(attendeeNumber);
	};

	useEffect(() => {
		if (attendeeNumberQuery) {
			saveAttendeeNumber(attendeeNumberQuery);
		}
	}, []);

	const onSubmit = (values: FormProps) => {
		setErrorText(undefined);
		if (currentForm === "attendee" && values.attendeeNumber) {
			saveAttendeeNumber(values.attendeeNumber);
		}
	};

	const logoutAttendee = () => {
		setHasPermission(false);
		setAttendeeNumber(null);
		localStorage.removeItem(PAYWALL_ATTENDEE_NUMBER);
	};

	const removeSeat = () => {
		api.removeUserFromSeat(attendeeNumber, analyticsUserId, channel?.id || eventNumber).then(res => {
			logoutAttendee();
		});
	};

	const authorizeAttendeeNumber = (channel: Channel, attendeeNumber: string, analyticsUserId: string) => {
		api.authorizeAttendeeNumber(channel.clientId, channel.key, attendeeNumber, analyticsUserId)
			.then(data => {
				if (data.data.success === true) {
					const user = authFirebaseApp.auth().currentUser;
					if (user && content && content.contentType) {
						updateAnalyticsUserDoc(
							user,
							content.id,
							{ analyticsUserId, attendeeNumber },
							content.contentType,
							false
						);
					}
					setHasPermission(true);
				} else {
					setErrorText(data.data.message);
					logoutAttendee();
				}
			})
			.catch(e => {
				console.log(e);
				logoutAttendee();
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitting(false);
			});
	};

	const authorizeBrushfireAttendeeNumber = (eventNumber: string, attendeeNumber: string, analyticsUserId: string) => {
		api.authorizeStandaloneAttendeeNumber(eventNumber, attendeeNumber, analyticsUserId)
			.then(data => {
				if (data.data.success === true) {
					const user = authFirebaseApp.auth().currentUser;
					if (user && content && content.contentType) {
						updateAnalyticsUserDoc(
							user,
							content.id,
							{ analyticsUserId, attendeeNumber },
							content.contentType,
							false
						);
					}
					setHasPermission(true);
				} else {
					setErrorText(data.data.message);
					logoutAttendee();
				}
			})
			.catch(e => {
				console.log(e);
				logoutAttendee();
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		let unsubscribe = () => { };
		if (attendeeNumber && !isSubmitting) {
			if (!isNaN(parseInt(attendeeNumber)) && attendeeNumber.toString().length >= 8) {
				if (channel) {
					//BFO Paywall
					setIsSubmitting(true);
					unsubscribe = db
						.collection("paywall_access")
						.doc(attendeeNumber)
						.onSnapshot(snap => {
							if (snap.exists) {
								const snapData = snap.data() as PaywallGlobalAccess;
								if (snapData.isCanceled || snapData.attendeeData.Status === "Cancelled") {
									setErrorText("This attendee has been canceled");
									logoutAttendee();
									setIsLoading(false);
									setIsSubmitting(false);
								} else if (
									!snapData.seats[channel.id] ||
									!snapData.seats[channel.id].find(
										seat => seat.analyticsUserId === analyticsUserId && !seat.exitTimestamp
									)
								) {
									// You're not in the list, so try and add yourself
									authorizeAttendeeNumber(channel, attendeeNumber, analyticsUserId);
								} else {
									if (
										channel.brushfireEventIds?.find(
											bfEvt => bfEvt.eventId === snapData.attendeeData.EventId
										)?.attendeeTypes[snapData.attendeeData.TypeId].numberOfScreens ||
										0 > 0
									) {
										if (channel.brushfireEventIds?.find(
											bfEvt => bfEvt.eventId === snapData.attendeeData.EventId
										)?.attendeeTypes[snapData.attendeeData.TypeId].orderRelativeDays ?? 0 > 0) {
											setAttendeeData(snapData.attendeeData);
											authorizeAttendeeNumber(channel, attendeeNumber, analyticsUserId);
										}
										else {
											setAttendeeData(snapData.attendeeData);
											setHasPermission(true);
											setIsLoading(false);
											setIsSubmitting(false);
											const user = authFirebaseApp.auth().currentUser;
											if (user && content && content.contentType) {
												updateAnalyticsUserDoc(
													user,
													content.id,
													{ analyticsUserId, attendeeNumber },
													content.contentType,
													false
												);
											}
										}
									} else {
										setHasPermission(false);
										setIsSubmitting(true);
									}
								}
							} else {
								setHasPermission(false);
								setIsSubmitting(true);
								authorizeAttendeeNumber(channel, attendeeNumber, analyticsUserId);
							}
						});
					//
				} else if (eventNumber) {
					//Standalone Paywall
					setIsSubmitting(true);
					unsubscribe = db
						.collection("paywall_access")
						.doc(attendeeNumber)
						.onSnapshot(snap => {
							if (snap.exists) {
								const snapData = snap.data() as PaywallGlobalAccess;
								if (snapData.isCanceled) {
									setErrorText("This attendee has been canceled");
									logoutAttendee();
									setIsLoading(false);
									setIsSubmitting(false);
								} else if (
									!snapData.seats[attendeeNumber] ||
									!snapData.seats[attendeeNumber].find(
										seat => seat.analyticsUserId === analyticsUserId && !seat.exitTimestamp
									)
								) {
									// You're not in the list, so try and add yourself
									authorizeBrushfireAttendeeNumber(eventNumber, attendeeNumber, analyticsUserId);
								} else {
									setAttendeeData(snapData.attendeeData);
									setHasPermission(true);
									setIsLoading(false);
									setIsSubmitting(false);
								}
							} else {
								setHasPermission(false);
								setIsSubmitting(true);
								authorizeBrushfireAttendeeNumber(eventNumber, attendeeNumber, analyticsUserId);
							}
						});
				}
			} else {
				setErrorText("Invalid Attendee Number Format");
				logoutAttendee();
				setIsLoading(false);
			}
		} else {
			logoutAttendee();
			setIsLoading(false);
		}
		return () => {
			unsubscribe();
		};
	}, [attendeeNumber, content?.id]);

	if (!hasPermission) {
		let isAttendeeForm = currentForm === "attendee";
		let initialValues = isAttendeeForm
			? { attendeeNumber: attendeeNumberQuery || localStorage.getItem(PAYWALL_ATTENDEE_NUMBER) || "" }
			: { email: "" };

		if (isLoading) {
			return <GlobalLoader isActive={isLoading} />;
		}

		return (
			<>
				{header && <>{header}</>}
				<PaywallContainer>
					<PaywallFormWrapper
						hasThumbImage={channel?.thumbImage}
						className={isMobile || isMobileLandscape ? "" : "absolute-center"}
						style={{ zIndex: 900 }}
					>
						{channel?.thumbImage && (
							<PaywallHeaderImageContainer>
								<PaywallImage src={channel.thumbImage} />
							</PaywallHeaderImageContainer>
						)}
						{headerImage && headerImage !== "" && (
							<PaywallHeaderImageContainer>
								<PaywallImage src={headerImage} />
							</PaywallHeaderImageContainer>
						)}

						<div className="paywall-content-container">
							<h4>Welcome to</h4>
							<h1>{title}</h1>
							{errorText && <PaywallErrorText>{errorText}</PaywallErrorText>}
							<Form initialValues={initialValues} onSubmit={onSubmit}>
								{({ formikContext }: { formikContext: any }) => {
									const { errors, values, touched } = formikContext;
									return (
										<fieldset disabled={isSubmitting}>
											{isAttendeeForm ? (
												<Form.Field
													label="Attendee Number"
													id="attendeeNumber"
													name="attendeeNumber"
													placeholder="XXXXXXXX"
												/>
											) : (
												<Form.Field
													label="Email"
													id="email"
													name="email"
													placeholder="mail@mail.com"
												/>
											)}

											<Button
												variant="primary"
												className="btn-block paywall-enter-button"
												type="submit"
												disabled={isSubmitting || isLoading}
											>
												{isSubmitting && (
													<Spinner animation="border" className="form-button-spinner" />
												)}{" "}
												{isAttendeeForm
													? `Enter${isSubmitting ? "ing" : ""}`
													: `Send${isSubmitting ? "ing" : ""} the link`}
											</Button>
										</fieldset>
									);
								}}
							</Form>
						</div>
					</PaywallFormWrapper>
				</PaywallContainer>
				{footer && <>{footer}</>}
			</>
		);
	}
	return (
		<>
			<AttendeeDataContext.Provider value={{ attendeeData, paywallEnabled: true, logout: removeSeat }}>
				{isFunction(children) ? children({ attendeeData, paywallEnabled: true, logout: removeSeat }) : children}
			</AttendeeDataContext.Provider>
		</>
	);
};

export default Paywall;
