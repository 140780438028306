import "firebase/auth";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Form, { FormContext } from "../../../components/Form";
import StickyTextInput from "../../../components/Form/StickyTextInput";
import { hideLoader, showLoader } from "../../../components/GlobalLoader";
import Sticky from "../../../components/Sticky";
import { useToast } from "../../../components/Toast/ToastProvider";
import firebase from "../../../firebase";
import { AvailableCodeProviders, BrushfireEventCondensed, Channel, Client } from "../../../interfaces";
import { NavigatorSection } from "../../../interfaces/NavigatorSection";
import { TypedUseSelectorHook } from "../../../redux";
import api from "../../../services/api";
import { addPreText } from "../../../utils/addPreText";
import { isChurchX } from "../../../utils/churchx";
import isValidDate from "../../../utils/isValidDate";
import Appearance from "../Appearance";
import { isMatchedTheme } from "../Theme/helpers";
import { PageContainer, PageTitle, Well } from "../UI";
import FormSection from "../UI/FormSection";
import { Sections } from "../UI/FormSections";
import FormSubmitButton from "../UI/FormSubmitButton";
import SectionedPageContainer from "../UI/SectionedPageContainer";
import { FooterLink } from "../UI/SectionedPageContainer/SectionedPageContainer";
import { channelFormInitialValues } from "./helpers";
import { validate } from "./validate";

interface MatchParams {
	clientId?: string;
	channelId?: string;
}

const useTypedSelector: TypedUseSelectorHook = useSelector;
const db = firebase.firestore();

const formSections = {
	details: "DETAILS",
	availability: "AVAILABILITY",
	theme: "THEME",
	defaultVideo: "DEFAULT_VIDEO",
	links: "LINKS",
	integrations: "INTEGRATIONS",
};

const ChannelForm: React.FC = () => {
	const [clientData, setClientData] = useState<Client>();
	const [channelData, setChannelData] = useState<Channel>();
	const [formValues, setFormValues] = useState<any>({});
	const [initialFormValues, setInitialFormValues] = useState<BrushfireEventCondensed[]>([]);
	const [action, setAction] = useState("");
	const [isSaving, setIsSaving] = useState(false);
	const [scheduleOption, setScheduleOption] = useState("0");
	const [touchedKey, setTouchedKey] = useState(false);
	const [inputWidth, setInputWidth] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const { clientId, channelId } = useRouteMatch<MatchParams>().params;
	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);

	const location = useLocation();
	const { addToast } = useToast();
	let clientHistory = useHistory();

	const isEditOrCopyChannel = channelId && channelId;
	const isDashboardDataLoading = dashboard.clientDataLoading || dashboard.channelDataLoading;

	useEffect(() => {
		showLoader();
		const { pathname } = location;
		const values = pathname.split("/");
		if (values.includes("add")) {
			setAction("add");
		} else {
			if (values.includes("copy")) {
				setAction("copy");
			} else {
				setAction("edit");
			}
		}
		// get current client data
		const clientData = dashboard.clientData;
		setClientData(clientData);

		if (isEditOrCopyChannel) {
			const channelData = dashboard.channelData;
			setChannelData(channelData);
		}
	}, [dashboard.channelData, dashboard.clientData, isEditOrCopyChannel]);

	useEffect(() => {
		if (action === "edit" || action === "copy") {
			if (!dashboard.channelDataLoading && channelData && !isEmpty(channelData)) {
				const formValues: Channel = {
					...channelData,
					name: channelData && addPreText(channelData.name, action),
					//@ts-ignore
					showAt: channelData.showAt
						? isValidDate(new Date(channelData.showAt))
							? new Date(channelData.showAt)
							: null
						: null,
					//@ts-ignore
					hideAt: channelData.hideAt
						? isValidDate(new Date(channelData.hideAt))
							? new Date(channelData.hideAt)
							: null
						: null,
					showAppearance: channelData.theme && !isEmpty(channelData.theme) ? false : true,
					showSettings:
						channelData.theme && !isEmpty(channelData.theme) && !isMatchedTheme(channelData.theme)
							? true
							: false,
					defaultVideoEnabled: channelData.defaultVideo && channelData.defaultVideo.src ? true : false,
				};

				if (action === "copy") {
					formValues.key = `copy-of-${channelData?.key}`;
				} else {
					formValues.key = channelData.key;
				}

				if (channelData?.showAt && !channelData?.hideAt) {
					setScheduleOption("1");
				} else if (!channelData?.showAt && channelData?.hideAt) {
					setScheduleOption("3");
				} else if (channelData?.showAt && channelData?.hideAt) {
					setScheduleOption("2");
				} else if (!channelData?.showAt && !channelData?.hideAt) {
					setScheduleOption("0");
				}
				if (!isEmpty(formValues.links)) {
					formValues.links.forEach((link: any) => {
						if (link.url) {
							link.linkType = "url";
						} else if (link.html) {
							link.linkType = "html";
						} else if (link.code) {
							link.linkType = "code";
							link.codeProvider = AvailableCodeProviders[link.codeProvider];
						}
					});
				} else {
					formValues.links = []; //Add it here because we added this after channels had been created
				}
				setFormValues(formValues);
				setInitialFormValues(formValues.brushfireEventIds?.concat() || []);
				setIsLoading(false);
				hideLoader();
			}
		} else if (action === "add" && clientData) {
			// inherit client theme when first adding a new Channel
			const formValues = {
				...channelFormInitialValues,
				theme: {},
				timeZoneName: clientData.timeZoneName ?? moment.tz.guess(),
				defaultVideoEnabled: false,
			};
			setFormValues(formValues);
			setInitialFormValues(formValues.brushfireEventIds?.concat() || []);
			setIsLoading(false);
			hideLoader();
		}
	}, [action, channelData, clientData, dashboard.clientDataLoading]);

	const onSubmit = async (formikvalues: any, resetForm: any) => {
		if (isEmpty(clientData) || !clientData) throw new Error("No client data");
		setIsSaving(true);
		const values = cloneDeep(formikvalues);

		const scheduleSetting = values.scheduleSetting;

		if (scheduleSetting === "1") {
			values.hideAt = null;
		} else if (scheduleSetting === "3") {
			values.showAt = null;
		} else if (scheduleSetting === "0") {
			values.hideAt = null;
			values.showAt = null;
		}

		const newValues: Channel = {
			...values,
			clientId: clientData.id,
			theme: !values.showAppearance ? values.theme || clientData.theme : {},
			showAt: values.showAt ? values.showAt.toISOString() : null,
			hideAt: values.hideAt ? values.hideAt.toISOString() : null,
			displayOrder: values.displayOrder ?? 0,
			versionCode: null,
			updatedBy: user.appUser?.email,
		};

		if (action === "add" || action === "copy") {
			newValues.id = uuidv4();
			newValues.createdAt = moment.utc().toISOString();
		} else {
			newValues.id = values.id;
		}

		if (!isEmpty(newValues.links)) {
			newValues.links?.forEach((link: any) => {
				delete link.linkType;
				if (link.hasOwnProperty("codeProvider") && link.hasOwnProperty("code")) {
					link.codeProvider = AvailableCodeProviders[link.codeProvider];
				}
			});
		}

		newValues.key = newValues.key?.toLowerCase();
		delete newValues.showSettings;
		delete newValues.defaultVideoEnabled;

		if (isEmpty(newValues.defaultVideo) || !newValues.defaultVideo || !values.defaultVideoEnabled) {
			delete newValues.defaultVideo;
		}

		db.collection("channels")
			.doc(newValues.id)
			.set(newValues)
			.then(() => {
				const clientKey = dashboard.clientData?.key;
				if (clientKey) {
					setTimeout(() => {
						api.fetchChannel(clientKey, newValues.key);
					}, 1000);
				}
				addToast("success", "Channel saved!");
				if (newValues.brushfireEventIds && newValues.brushfireEventIds?.length > 0) {
					db.collection("channel_events")
						.doc(newValues.createdAt ? moment(newValues.createdAt).format("YYYYMM") : "older")
						.set(
							{ [newValues.id]: newValues.brushfireEventIds.map(bfEvt => bfEvt.eventNumber) },
							{ merge: true }
						)
						.then(() => {
							//update Webhook
							api.updateWebhook()
								.then(() => {
									if (action !== "edit") {
										let url = `//${window.location.host}/dashboard/${newValues.clientId}/channels`;
										window.open(url, "_self");
									}
								})
								.catch(() => {
									addToast("error", "Error connecting Brushfire Events.");
								});
						});
				} else if (
					newValues.brushfireEventIds &&
					newValues.brushfireEventIds?.length === 0 &&
					initialFormValues &&
					initialFormValues?.length > 0
				) {
					const channelEventRef = db
						.collection("channel_events")
						.doc(newValues.createdAt ? moment(newValues.createdAt).format("YYYYMM") : "older");
					channelEventRef.get().then(doc => {
						const channelEventData = doc.data() as { [channelIdString: string]: string[] };
						delete channelEventData[newValues.id];
						channelEventRef.set(channelEventData).then(() => {
							//update Webhook
							api.updateWebhook()
								.then(() => {
									if (action !== "edit") {
										let url = `//${window.location.host}/dashboard/${newValues.clientId}/channels`;
										window.open(url, "_self");
									}
								})
								.catch(err => {
									addToast("error", "Error disconnecting Brushfire Events.");
									console.warn("error removing brushfire integration: ", err);
								});
						});
					});
				} else {
					if (action !== "edit") {
						let url = `//${window.location.host}/dashboard/${newValues.clientId}/channels`;
						window.open(url, "_self");
					}
				}
				resetForm({ values: values });
			})
			.catch(error => {
				addToast("error", "Error submitting form.");
				console.warn("Error submitting form", error);
			})
			.finally(() => {
				setIsSaving(false);
			});
	};

	const validateForm = (values: any) => {
		return validate(values, clientId, channelId);
	};

	const buildUrlPrefix = (): string => {
		if (clientData && clientData.customDomain) {
			return `${clientData.customDomain}/`;
		} else {
			if (isChurchX) {
				return `churchx.online/${clientData ? clientData.key : "organization"}/`;
			} else {
				return `online.brushfire.com/${clientData ? clientData.key : "organization"}/`;
			}
		}
	};

	const videoProviderData = [
		{ value: "youtube", label: "Youtube" },
		{ value: "vimeo", label: "Vimeo" },
		{ value: "facebook", label: "Facebook" },
	];

	const sections: NavigatorSection[] = [
		{
			label: "Details",
			key: formSections.details,
		},
		{
			label: "Access",
			key: formSections.availability,
		},
		{
			label: "Theme",
			key: formSections.theme,
		},
		{
			label: "Default Video",
			key: formSections.defaultVideo,
		},
		{
			label: "Links",
			key: formSections.links,
		},
		{
			label: "Integrations",
			key: formSections.integrations,
		},
	];

	const footerLinks: FooterLink[] = [];

	if (action === "edit") {
		footerLinks.push({
			label: "Edit",
			href: `//${window.location.host}/dashboard/${dashboard.clientData?.id}/channels/${dashboard.channelData?.id}`,
			icon: "fal fa-pencil",
			key: "edit",
		});

		footerLinks.push({
			label: "Preview",
			href: `//${window.location.host}/${dashboard.clientData?.key}/${dashboard.channelData?.key}`,
			icon: "fal fa-globe",
			key: "preview",
		});
	}

	if (isDashboardDataLoading || isLoading) {
		return <PageContainer htmlPageTitle="Loading..." />;
	} else if (!isEmpty(formValues)) {
		return (
			<SectionedPageContainer
				size="md"
				sections={sections}
				defaultSection={formSections.details}
				pageSupertitle="Channel"
				//@ts-ignore
				pageTitle={action !== "add" ? formValues.name : "New Channel"}
				footerLinks={footerLinks}
				htmlPageTitle="Channel Configuration"
			>
				<Form initialValues={formValues} validate={validateForm}>
					{({ formikContext, extraData }: FormContext<any>) => {
						const {
							values: { key, theme, name, description, chatWidget },
							setFieldValue,
							errors,
							handleBlur,
							touched,
							isValid,
							dirty,
							resetForm,
						} = formikContext;

						return (
							<>
								{dirty && <Form.Prompt isSaving={isSaving} />}
								{extraData.showSummary && formikContext.errors && (
									<Form.Summary errors={formikContext.errors} handleFormSections />
								)}

								<fieldset disabled={isSaving}>
									<FormSection sectionKey={formSections.details}>
										<div className="text-center">
											<PageTitle>DETAILS</PageTitle>
										</div>
										<Well
											message="Channels allow you to organize your content and provide a unique experience to match."
											learnMoreLink="https://support.brushfire.com/hc/en-us/articles/22237801361165-Adding-Channels-to-Brushfire-Online"
										>
											<Form.Field
												label="Name *"
												id="name"
												name="name"
												placeholder="Channel Name"
												className="channel-name field"
												onChange={(event: any) => {
													setFieldValue("name", event.target.value);

													if (!touchedKey && action === "add") {
														setFieldValue(
															"key",
															event.target.value
																.replace(/[^a-zA-Z0-9]/g, "-")
																.toLowerCase()
														);
													}
												}}
											/>
											<StickyTextInput prefix={buildUrlPrefix()}>
												<Form.Field
													label={`URL *`}
													id="key"
													name="key"
													placeholder=""
													className="channel-key field animation-stopper"
													onBlur={(e: any) => {
														setTouchedKey(true);
														handleBlur(e);
													}}
													onChange={(event: any) => {
														let newString = event.target.value.replace(
															/[^a-zA-Z0-9]/g,
															"-"
														);
														setFieldValue("key", newString.toLowerCase());
													}}
												/>
											</StickyTextInput>
											<Form.Field
												label="Google Analytics Tracking Id"
												id="analytics.google[0].trackingId"
												name="analytics.google[0].trackingId"
												placeholder="Google Analytics Tracking Number"
											/>
										</Well>
									</FormSection>
									<FormSection sectionKey={formSections.availability}>
										<div className="text-center form-section-title">
											<PageTitle>Visibility</PageTitle>
										</div>
										<Well message="Private channels are only accessible via a direct link and will not be indexed by search engines.">
											<>
												<Form.Field
													component={Form.Input.Checkbox}
													label="MAKE THIS CHANNEL PRIVATE"
													id="isPrivate"
													name="isPrivate"
												/>
											</>
										</Well>
										<div className="text-center form-section-title">
											<PageTitle>Availability</PageTitle>
										</div>
										<Well message="Control how and when your channel is available to the public. Private channels are only accessible via a direct link and will not be indexed by search engines.">
											<>
												<Form.Field
													component={Form.Input.Select}
													label="Make my channel available"
													id="scheduleSetting"
													name="scheduleSetting"
													value={scheduleOption}
													onChange={(option: any) => {
														setScheduleOption(option.value);
														if (option.value === "0") {
															setFieldValue("showAt", null);
															setFieldValue("hideAt", null);
														} else if (option.value === "1") {
															setFieldValue("hideAt", null);
														} else if (option.value === "3") {
															setFieldValue("showAt", null);
														}
													}}
													data={[
														{ value: "0", label: "Always" },
														{ value: "1", label: "After a specified date" },
														{ value: "2", label: "Between two dates" },
														{ value: "3", label: "Until a specified date" },
													]}
													isSearchable={false}
												/>
												{scheduleOption !== "0" && (
													<Row>
														<Col xs={12} sm={6}>
															{scheduleOption === "1" || scheduleOption === "2" ? (
																<Form.Field
																	type="date"
																	component={Form.Input.DateTime}
																	label="After"
																	id={`showAt`}
																	name={`showAt`}
																	displayTimeZone={
																		clientData?.timeZoneName ??
																		formikContext.values.timeZoneName
																	}
																/>
															) : null}
														</Col>
														<Col xs={12} sm={6}>
															{scheduleOption === "2" || scheduleOption === "3" ? (
																<Form.Field
																	type="date"
																	component={Form.Input.DateTime}
																	label="Until"
																	id={`hideAt`}
																	name={`hideAt`}
																	displayTimeZone={
																		clientData?.timeZoneName ??
																		formikContext.values.timeZoneName
																	}
																/>
															) : null}
														</Col>
													</Row>
												)}
											</>
										</Well>
										<Sections.BrushfireEvent
											name="brushfireEventIds"
											formikContext={formikContext}
											wellMessage={
												<>
													Have an event in Brushfire? Connect this channel to your event and
													restrict access to attendees. If you’re not ready for attendees to
													visit this channel yet, set the dates above.
												</>
											}
											disabled={isSaving}
											initialFormValues={initialFormValues}
										/>
									</FormSection>
									<FormSection sectionKey={formSections.theme}>
										<div className="text-center">
											<PageTitle>THEME</PageTitle>
										</div>
										<Well message="Your overall theme can be controlled in your Organization Settings. If you would like to add a separate theme for a specific Channel, you can do so here.">
											<h3>CHANNEL ARTWORK</h3>
											<Form.Field
												component={Form.Input.Uploader}
												clientId={clientId}
												isSaving={isSaving}
												label="Header Image"
												id="headerImage"
												name="headerImage"
												placeholder="Your Header Image URL"
												className="channel-headerImage field"
												helperText="Recommended size is 1200px by 200px at 144 DPI"
											/>
											<Form.Field
												component={Form.Input.Uploader}
												clientId={clientId}
												isSaving={isSaving}
												label="Thumb Image"
												id="thumbImage"
												name="thumbImage"
												placeholder="Your Thumb Image URL"
												className="channel-thumbImage field"
												helperText="Recommended size is 1920px by 1080px at 144 DPI"
											/>

											{clientId && (
												<Appearance
													clientId={clientId}
													isChannel
													customAppearance
													isSaving={isSaving}
													formikContext={formikContext}
												/>
											)}
										</Well>
									</FormSection>
									<FormSection sectionKey={formSections.defaultVideo}>
										<div className="text-center form-section-title">
											<PageTitle>Default Video</PageTitle>
											<Form.Field
												component={Form.Input.Checkbox}
												label=""
												id="defaultVideoEnabled"
												name="defaultVideoEnabled"
												switchToggle={true}
											/>
										</div>
										{formikContext.values.defaultVideoEnabled ? (
											<Well message="Customize the look of you channel below to match your content and provide a unique viewing experience.">
												<Form.Field
													component={Form.Input.Select}
													label="Video Provider"
													id="defaultVideo.videoProvider"
													name="defaultVideo.videoProvider"
													data={videoProviderData}
													isSearchable={false}
												/>
												{formikContext.values.defaultVideo &&
													formikContext.values.defaultVideo.videoProvider && (
														<Form.Field
															label="Video URL"
															id="defaultVideo.src"
															name="defaultVideo.src"
															className="field"
														/>
													)}
											</Well>
										) : (
											<Well>
												<p>
													Customize the look of you channel below to match your content and
													provide a unique viewing experience.{" "}
													{/* <span>
													<a
														href="https://brushfire.zendesk.com/hc/en-us/articles/360013222999"
														target="_blank"
														rel="noopener noreferrer"
													>
														Learn more <i className="fal fa-long-arrow-right" />
													</a>
												</span> */}
												</p>
											</Well>
										)}
									</FormSection>
									<FormSection sectionKey={formSections.links}>
										<Sections.Links
											name="links"
											formikContext={formikContext}
											wellMessage="Links appear in the navigation at the top of all of your pages. You can
												open links in a new tab or a modal to display content on the page."
											learnMoreLink="https://support.brushfire.com/hc/en-us/articles/22238049808525-Adding-Links-and-Giving-to-Brushfire-Online"
										/>
									</FormSection>

									<FormSection sectionKey={formSections.integrations}>
										<Sections.Integrations
											formikContext={formikContext}
											isEnabled={chatWidget?.enabled || false}
										/>
									</FormSection>

									{/* <div className="text-center">
										<PageTitle>BRUSHFIRE EVENT</PageTitle>
									</div>
	
									<Well>
										
									</Well> */}
									<Sticky>
										<FormSubmitButton
											disabled={isSaving}
											isSubmitting={isSaving}
											isAddForm={action !== "edit"}
											onClick={() => {
												if (isValid && !isEmpty(touched)) {
													onSubmit(formikContext.values, resetForm);
												} else if (!isValid) {
													window.scrollTo({ top: 0, behavior: "smooth" });
												}
											}}
										/>
									</Sticky>
								</fieldset>
							</>
						);
					}}
				</Form>
			</SectionedPageContainer>
		);
	} else {
		return (
			<PageContainer htmlPageTitle="Channels">
				<div style={{ color: "white", fontSize: 100 }}>404</div>
				<div style={{ color: "white" }}>Channel not found.</div>
			</PageContainer>
		);
	}
};

export default ChannelForm;
