import styled from "styled-components";
import { Theme } from "../../interfaces/Theme";

interface ThemeProps {
	theme: Theme;
	hasThumbImage: any;
}

const PaywallContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PaywallFormWrapper = styled.div<ThemeProps>`
	width: 100%;
	max-width: 768px;
	background-color: ${({ theme }) => theme.wellBackgroundColor};
	border-radius: 10px;
	color: ${({ theme }) => theme.wellTextColor};
	overflow: hidden;

	.paywall-content-container {
		padding: 25px;
		width: 100%;

		.paywall-helper-link {
			font-size: 12px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 5px;

			a {
				color: ${({ theme }) => theme.wellTextColor};
				text-decoration: underline;
			}
		}

		.horizontal-rule {
			.horizontal-rule-label {
				padding: 10px 25px;
				background-color: ${({ theme }) => theme.wellBackgroundColor};
			}
			&:before {
				background-color: ${({ theme }) => theme.textColor};
			}
		}

		.paywall-enter-button {
			background-color: ${({ theme }) => theme.buttonBackgroundColor};
			color: ${({ theme }) => theme.buttonTextColor};
			border: none;
			transition: all ease-in-out 0.3s;
			&:hover {
				background-color: ${({ theme }) => theme.buttonHoverBackgroundColor};
				opacity: 1;
			}
		}

		.paywall-toggle-button {
			background-color: #dadada;
			color: #595959;
			border: none;
			margin-top: 10px;
			font-size: 16px;

			.ta-center {
				padding: 0;
			}

			&:focus {
				outline: none !important;
				outline-offset: none !important;
			}
		}
	}
	h1 {
		font-size: 32px;
		overflow-wrap: anywhere;
	}

	h4 {
		font-size: 18px;
		margin: 0;
	}

	label {
		font-size: 14px;
		color: ${({ theme }) => theme.wellTextColor};
	}

	input {
		background-color: #dadada !important;
		color: #595959 !important;
		border: none !important;
	}

	@media screen and (max-width: 992px) {
		width: 70%;
	}

	@media screen and (max-width: 600px) {
		width: 100%;
		height: 100%;
		min-height: calc(100vh - 40px);
		border-radius: 0px;

		${({ hasThumbImage }) => {
			if (!hasThumbImage) {
				return `display: flex;
						align-items: center;
					`;
			}
		}};
	}
`;

const PaywallHeaderImageContainer = styled.div`
	position: relative;
`;

const PaywallImage = styled.img`
	width: 100%;
`;

const PaywallErrorText = styled.div`
	background-color: #e71d36;
	color: #fffefd;
	padding: 10px;
	border-radius: 4px;
`;

export { PaywallContainer, PaywallFormWrapper, PaywallHeaderImageContainer, PaywallImage, PaywallErrorText };
