import { includes, isEmpty, values as _values, keys as _keys } from "lodash";
import moment from "moment";
import { REGEX } from "../../../constants";
import firebase from "../../../firebase";
import { Channel, Link } from "../../../interfaces";
const db = firebase.firestore();

export const validate = async (values: Channel, clientId: string | undefined, channelId: string | undefined) => {
	const errors: any = {};

	// name
	if (isEmpty(values.name)) {
		errors.name = "Name is required";
	}

	// key
	if (isEmpty(values.key)) {
		errors.key = "URL is required";
	} else if (!isEmpty(values.key)) {
		if (values.key.length < 2) {
			errors.key = "URL needs to be at least 2 characters";
		} else if (values.key.length > 50) {
			errors.key = "URL cannot be more than 50 characters";
		}

		if (values.key.match(/[^a-zA-Z0-9-]/g)) {
			errors.key = "URL can only include letters, numbers, and dashes";
		}

		const checkDb = async () => {
			return new Promise(async (resolve, reject) => {
				try {
					if (values.key) {
						await db
							.collection("channels")
							.where("clientId", "==", clientId)
							.where("key", "==", values.key.toLowerCase())
							.where("versionCode", "==", null)
							.get()
							.then(async docs => {
								await docs.forEach(async doc => {
									const channel: Channel = doc.data() as Channel;
									if (channel.id !== channelId) {
										resolve(false);
									}
								});
								resolve(true);
							})
							.catch(() => {
								resolve(true);
							});
					} else {
						resolve(false);
					}
				} catch (err) {
					console.log(err);
					errors.key = "Something went wrong. Please try again";
					resolve(false);
				}
			});
		};
		const res = await checkDb();
		if (!res) {
			errors.key = "URL is already taken";
		}
	}

	// startsAt && hidesAt
	if (values.showAt && values.hideAt) {
		if (moment(values.showAt).isAfter(values.hideAt)) {
			errors.showAt = "The After date can not be later than the Until date";
		}
	}

	//defaultVideo
	if (values.defaultVideoEnabled) {
		if (!isEmpty(values.defaultVideo) && values.defaultVideo) {
			if (!values.defaultVideo.videoProvider) {
				errors.defaultVideo = "Please select a Default Video provider";
			}
			if (!values.defaultVideo.src || values.defaultVideo.src === undefined) {
				errors.defaultVideo = "Please enter a Default Video URL";
			}

			if (values.defaultVideo.src) {
				if (!values.defaultVideo.src.match(REGEX.URL)) {
					errors.defaultVideo = "Default Video URL needs to be a valid URL (ex. https://example.com)";
				}
			}
		}
	}

	// homeUrl
	if (values.homeUrl) {
		if (!values.homeUrl.match(REGEX.URL)) {
			errors.homeUrl = "Logo URL needs to be a valid URL (ex. https://example.com)";
		}
	}

	//integrations
	if (values.chatWidget && values.chatWidget.enabled && !values.chatWidget.provider) {
		errors.chatWidget = "Provider Required on Chat Widget";
	}
	if (values.chatWidget && values.chatWidget.enabled) {
		if (!values.chatWidget.embedId || values.chatWidget.embedId === "") {
			errors.chatWidget = "Key Required on Chat Widget";
		} else if (!values.chatWidget.embedId.match(REGEX.GUID)) {
			errors.chatWidget = "Please add a valid key";
		}
	}
	if (values.theme) {
		Object.keys(values.theme).forEach(key => {
			if (
				key.includes("Color") &&
				//@ts-ignore
				values.theme[key] &&
				//@ts-ignore
				values.theme[key] !== "" &&
				//@ts-ignore
				!values.theme[key].match(REGEX.HEX_COLOR)
			) {
				if (!errors.theme) {
					errors.theme = {};
				}
				errors.theme[key] = "Please use a valid hexadecimal color code";
			}
		});
	}
	if (!isEmpty(values.links)) {
		values.links.forEach((link: Link, index: number) => {
			const linkError: any = {};
			if (isEmpty(link.text)) {
				linkError.text = `(Link ${index + 1}) Link Name is required`;
			}
			if (link.url) {
				const linkUrlError: any = {};
				if (isEmpty(link.url.url)) {
					linkUrlError.url = `(Link ${index + 1}) External Link is required`;
				} else if (!link.url.url.match(REGEX.URL)) {
					linkUrlError.url = `(Link ${index +
						1}) External Link needs to be a valid URL (ex. https://example.com)`;
				}
				if (isEmpty(link.url.target)) {
					linkUrlError.target = `(Link ${index + 1}) Target is required`;
				} else if (!includes(["newTab", "sameTab", "iframeModal", "sideBar"], link.url.target)) {
					linkUrlError.target = `(Link ${index + 1}) Unknown URL target`;
				}

				if (!isEmpty(linkUrlError)) {
					linkError.url = linkUrlError;
				}
			}
			if (link.hasOwnProperty("codeProvider")) {
				if (isEmpty(link.codeProvider)) {
					linkError.codeProvider = `(Link ${index + 1}) A code provider must be chosen`;
				}
				if (isEmpty(link.code)) {
					linkError.code = `(Link ${index + 1}) A code provider key must be provided`;
				}
			}
			if (link.hasOwnProperty("html")) {
				if (isEmpty(link.html)) {
					linkError.html = `$(Link ${index + 1}) HTML content is required`;
				}
			}

			if (isEmpty(errors.links)) {
				errors.links = [];
			}
			errors.links.push(linkError);
		});

		const anyLinkErrors = errors.links.some((l: any) => !isEmpty(l));
		if (!anyLinkErrors) {
			delete errors.links;
		}
	}
	if (values.analytics) {
		values.analytics.google.forEach((g, index) => {
			if (g.trackingId !== "" && !g.trackingId.match(REGEX.GOOGLE_UA_ID)) {
				if (!errors.analytics) {
					errors.analytics = {};
				}
				if (!errors.analytics.google) {
					errors.analytics.google = [];
				}
				errors.analytics.google[index] = { trackingId: "Please use a valid Google Analytics Tracking Id" };
			}
		});
	}
	if (values.chatWidget?.enabled) {
		if (!values.chatWidget.embedId) {
			errors.chatWidget = { embedId: "Key is required" };
		} else if (!values.chatWidget.embedId.match(REGEX.GUID)) {
			errors.chatWidget = { embedId: "Key must be a valid format" };
		}
	}

	if (values.brushfireEventIds && values.brushfireEventIds.length > 0) {
		values.brushfireEventIds.forEach((event, index) => {
			let attendeeTypeValues = _values(event.attendeeTypes);
			let attendeeTypeKeys = _keys(event.attendeeTypes);

			attendeeTypeValues.forEach((val, i) => {
				if (val.numberOfScreens && val.numberOfScreens > 100) {
					let attendeeTypeId: string = attendeeTypeKeys[i];

					if (!errors.brushfireEventIds) {
						errors.brushfireEventIds = [];
					}

					errors.brushfireEventIds[index] = { attendeeTypes: {} };
					errors.brushfireEventIds[index].attendeeTypes[attendeeTypeId] = {
						numberOfScreens: "Max screens cannot be more than 100",
					};
				}
			});
		});
	}
	return errors;
};
