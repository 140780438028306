import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import firebaseApp from "../../../../firebase";
import {
	AnalyticsProcessed,
	ChatMessage,
	ChatRequest,
	Content,
	ContentAnalyticsResults,
	ContentType,
	ReactionIcon,
} from "../../../../interfaces";
import { NavigatorSection } from "../../../../interfaces/NavigatorSection";
import { TypedUseSelectorHook } from "../../../../redux";
import api from "../../../../services/api";
import userHasRole from "../../../../services/userHasRole";
import { PageTitle, SectionedPageContainer, Well } from "../../UI";
import HostChats from "./HostChats";

const db = firebaseApp.firestore();

const useTypedSelector: TypedUseSelectorHook = useSelector;

interface MatchParams {
	clientId: string;
	channelId: string;
	contentId: string;
}

interface Messages {
	[key: string]: ChatMessage[];
}

const pad2 = (number: number) => {
	return (number < 10 ? "0" : "") + number;
};

const getDeviceCategoryPercentage = (deviceCategories: { [key: string]: string }) => {
	const mobile = Number(deviceCategories["mobile"] || 0);
	const tablet = Number(deviceCategories["tablet"] || 0);
	const desktop = Number(deviceCategories["desktop"] || 0);
	const total = mobile + tablet + desktop;
	const mobilePercentage = Math.round((mobile / total) * 100);
	const tabletPercentage = Math.round((tablet / total) * 100);
	const desktopPercentage = Math.round((desktop / total) * 100);
	return {
		mobile: Number.isNaN(mobilePercentage) ? 0 : mobilePercentage,
		tablet: Number.isNaN(tabletPercentage) ? 0 : tabletPercentage,
		desktop: Number.isNaN(desktopPercentage) ? 0 : desktopPercentage,
	};
};

const checkDataIsValid = (value: string | null) => {
	return value && value !== "" && !value.includes("https://storage.googleapis.com/");
};

const ContentAnalytics: React.FC = () => {
	const [data, setData] = useState<ContentAnalyticsResults>();
	const [error, setError] = useState<string>();
	const [contentData, setContentData] = useState<Content>();
	const [isLoading, setIsLoading] = useState(true);
	const [hostChats, setHostChats] = useState<ChatRequest[]>([]);
	const [analyticsStatus, setAnalyticsStatus] = useState<AnalyticsProcessed>();
	const [sections, setSections] = useState<NavigatorSection[]>([
		{
			label: "Viewers",
			key: "viewers",
		},
	]);
	const [currentPanel, setCurrentPanel] = useState("viewers");
	const match: MatchParams = useRouteMatch<MatchParams>().params;

	const { contentId } = match;

	const dashboard = useTypedSelector(store => store.dashboard);
	const user = useTypedSelector(store => store.user);
	const isAdmin =
		dashboard.clientId && user.appUser ? userHasRole(dashboard.clientId, user.appUser, ["owner", "admin"]) : false;
	const isGlobalAdmin = user.appUser?.globalRole === "admin" || user.appUser?.globalRole === "owner";

	const fetchHostChats = async () => {
		await db
			.collection("chat")
			.doc("direct")
			.collection(contentId)
			.where("hostChat", "==", true)
			.get()
			.then(data => {
				if (data) {
					const hostChats: ChatRequest[] = [];
					data.forEach(async chat => {
						const hostChat = chat.data() as ChatRequest;
						if (hostChat.showRequest === true) {
							hostChats.push(hostChat);
						}
					});
					setHostChats(hostChats);
				}
			});
	};

	const markAnalyticsUnprocessed = async (isOnDemand?: boolean) => {
		const batch = db.batch();
		const contentDocRef = db.collection("contents").doc(contentId);
		const analyticsRunDocRef = db
			.collection("analytics")
			.doc("process")
			.collection(contentId)
			.doc("run");
		const analyticsViewersDocRef = db
			.collection("analytics")
			.doc("process")
			.collection(contentId)
			.doc("viewers");

		batch.delete(analyticsViewersDocRef);
		batch.delete(analyticsRunDocRef);
		const payload: Partial<Content> = {
			analyticsProcessed: AnalyticsProcessed.unprocessed,
		};
		if (isOnDemand) {
			payload["runAnalytics"] = true;
		}
		batch.update(contentDocRef, payload);
		await batch.commit();
	};

	const refreshAnalytics = () => {
		markAnalyticsUnprocessed(contentData?.contentType === ContentType.onDemand);
	};

	const fetchAnalyticsData = async (refresh: boolean) => {
		setIsLoading(true);
		await api
			.getContentAnalytics(contentId, refresh)
			.then(({ data }) => {
				const d = data as ContentAnalyticsResults;
				if (!data || data.success === false) {
					setError("Permission denied for this content. Talk with an admin to gain access.");
				} else {
					if (
						checkDataIsValid(d.paywallCsv) ||
						checkDataIsValid(d.chatTranscriptCsv) ||
						checkDataIsValid(d.answerData) ||
						checkDataIsValid(d.chatDataCsv)
					) {
						fetchAnalyticsData(true);
					} else {
						let interactionsTab = sections.find(x => x.label === "Interactions");
						if (!isEmpty(data?.interactions) && !interactionsTab) {
							setSections(sections => [
								...sections,
								{
									label: "Interactions",
									key: "interactions",
								},
							]);
						}
						setData(data as ContentAnalyticsResults);
					}
				}
			})
			.catch(err => {
				setError("An error occured while aggregating your analytics. Please try again later.");
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (contentData?.analyticsProcessed === AnalyticsProcessed.processed && !hostChats.length) {
			fetchHostChats();
		}
	}, [contentData]);

	useEffect(() => {
		db.collection("contents")
			.doc(contentId)
			.get()
			.then(data => {
				if (data.exists) {
					const content = data.data() as Content;
					setContentData(content);
					if (content.chat.enabled) {
						setSections(sections => [
							...sections,
							{
								label: "Chat",
								key: "chat",
							},
						]);
					}
				}
			});
	}, [contentId]);

	useEffect(() => {
		let unsubscribe = () => {};
		const contentDoc = db.collection("contents").doc(contentId);
		unsubscribe = contentDoc.onSnapshot(
			snapshot => {
				let contentData = snapshot.data();
				if (
					contentData?.analyticsProcessed === undefined ||
					contentData?.analyticsProcessed === AnalyticsProcessed.processed
				) {
					fetchAnalyticsData(false);
					setAnalyticsStatus(AnalyticsProcessed.processed);
				} else if (contentData?.analyticsProcessed === AnalyticsProcessed.processing) {
					setAnalyticsStatus(AnalyticsProcessed.processing);
				} else {
					setAnalyticsStatus(AnalyticsProcessed.unprocessed);
					if (contentData.runAnalytics !== true && contentData?.contentType === ContentType.onDemand) {
						markAnalyticsUnprocessed(true);
					}
				}
			},
			error => {
				console.log(`Encountered error: ${error}`);
			}
		);
		return () => {
			unsubscribe();
		};
	}, []);

	const downloadFile = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.currentTarget) {
			const href = e.currentTarget.getAttribute("href");
			const download = e.currentTarget.getAttribute("download");
			if (href) {
				var oReq = new XMLHttpRequest();
				oReq.open("GET", href, true);
				oReq.responseType = "blob";
				oReq.onload = function(oEvent) {
					if (oReq.response) {
						let hiddenElement = document.createElement("a");
						hiddenElement.href = window.URL.createObjectURL(oReq.response);
						hiddenElement.target = "_blank";
						hiddenElement.download = download ?? `${contentData?.name}-report.csv`;
						hiddenElement.click();
					} else console.error("we didnt get an XHR response!");
				};
				oReq.send(null);
			}
		}
	};

	let hours = 0;
	let minutes = 0;
	let seconds = 0;
	let averageHours = 0;
	let averageMinutes = 0;
	let averageSeconds = 0;
	const reactionNumbers: { [key: string]: number } = {};
	if (data) {
		hours = Math.floor(data.totalWatchTime / 3600);
		minutes = Math.floor(data.totalWatchTime / 60) - hours * 60;
		seconds = data.totalWatchTime % 60;
		averageHours = Math.floor(data.averageWatchTime / 3600);
		averageMinutes = Math.floor(data.averageWatchTime / 60) - averageHours * 60;
		averageSeconds = Math.round(data.averageWatchTime) % 60;
		Object.keys(data?.reactions || {}).forEach(key => {
			const arr = key.split("_");
			if (!reactionNumbers[arr[0]]) {
				reactionNumbers[arr[0]] = 0;
			}
			reactionNumbers[arr[0]] += data.reactions[key];
		});
	}

	if (!dashboard.clientData) {
		return null; //This is just for the page titles, so they aren't overridden by the client name
	}

	const footerLinks = [];
	if (dashboard.contentData) {
		if (isAdmin) {
			footerLinks.push({
				label: "Edit",
				href: `//${window.location.host}/dashboard/${dashboard.contentData.clientId}/contents/${dashboard.contentData.id}`,
				icon: "fal fa-pencil",
				key: "edit",
			});
		}
		footerLinks.push({
			label: "Analytics",
			href: `//${window.location.host}/dashboard/${dashboard.contentData.clientId}/contents/${dashboard.contentData.id}/analytics`,
			icon: "fal fa-analytics",
			key: "analytics",
		});
		footerLinks.push({
			label: "Host Panel",
			href: `//${window.location.host}/dashboard/${dashboard.contentData.clientId}/contents/${dashboard.contentData.id}/host`,
			icon: "fal fa-comments",
			key: "host",
		});
		const channels = dashboard.channelsData;
		if (channels && channels.length > 0) {
			let channel = channels[0];
			footerLinks.push({
				label: "Preview",
				href: `//${window.location.host}/${dashboard.clientData?.key}/${channel.key}/${dashboard.contentData?.key}`,
				icon: "fal fa-globe",
				key: "preview",
			});
		}
	}

	return (
		<SectionedPageContainer
			size={currentPanel === "chat" && hostChats.length ? "lg" : "md"}
			sections={sections}
			defaultSection={"viewers"}
			footerLinks={footerLinks}
			pageTitle={currentPanel.charAt(0).toUpperCase() + currentPanel.slice(1)}
			pageSupertitle={"Analytics"}
			htmlPageTitle="Content Analytics"
			toggleSectionPanel={setCurrentPanel}
		>
			{error && <div className="alert error">{error}</div>}
			{analyticsStatus === AnalyticsProcessed.processed && (
				<>
					{currentPanel === "viewers" && (
						<>
							<PageTitle>Viewers</PageTitle>
							<Well message="Analytics may be delayed by up to 24 hours for some stats.">
								<div className="pill-row">
									<div className={data?.maxLive !== 0 ? "pill" : "pill pill-4"}>
										<h5>
											Unique
											<br />
											Viewers
										</h5>
										<ReactCSSTransitionGroup
											transitionName="fade"
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}
										>
											{isLoading ? (
												<i className="fal fa-spin fa-spinner-third" />
											) : (
												<h3>{data?.uniqueViewers || "0"}</h3>
											)}
										</ReactCSSTransitionGroup>
									</div>
									{data?.maxLive !== 0 && (
										<div className="pill">
											<h5>
												Peak Simultaneous
												<br />
												Viewers
											</h5>
											<ReactCSSTransitionGroup
												transitionName="fade"
												transitionEnterTimeout={500}
												transitionLeaveTimeout={300}
											>
												{isLoading ? (
													<i className="fal fa-spin fa-spinner-third" />
												) : (
													<h3>{data?.maxLive || "0"}</h3>
												)}
											</ReactCSSTransitionGroup>
										</div>
									)}

									<div className={data?.maxLive !== 0 ? "pill" : "pill pill-4"}>
										<h5>
											Total View
											<br />
											Duration
										</h5>
										<ReactCSSTransitionGroup
											transitionName="fade"
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}
										>
											{isLoading ? (
												<i className="fal fa-spin fa-spinner-third" />
											) : (
												<h3>
													{hours > 0 ? `${hours}:` : ""}
													{minutes > 0 || hours > 0 ? `${pad2(minutes)}:` : "00:"}
													{seconds > 0 || minutes > 0 || hours > 0 ? pad2(seconds) : "00"}
												</h3>
											)}
										</ReactCSSTransitionGroup>
									</div>
									<div className={data?.maxLive !== 0 ? "pill" : "pill pill-4"}>
										<h5>
											Average View
											<br />
											Duration
										</h5>
										<ReactCSSTransitionGroup
											transitionName="fade"
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}
										>
											{isLoading ? (
												<i className="fal fa-spin fa-spinner-third" />
											) : (
												<h3>
													{averageHours > 0 ? `${averageHours}:` : ""}
													{averageMinutes > 0 || averageHours > 0
														? `${pad2(averageMinutes)}:`
														: "00:"}
													{averageSeconds > 0 || averageMinutes > 0 || averageHours > 0
														? pad2(averageSeconds)
														: "00"}
												</h3>
											)}
										</ReactCSSTransitionGroup>
									</div>
								</div>
								{contentData?.reactions?.enabled && (
									<div className="pill-row">
										<div className="pill pill-12">
											<h5>Reactions</h5>
											<div className="reaction-counts large">
												{Object.values(ReactionIcon).map(icon => {
													if (contentData.reactions.icons[icon]) {
														return (
															<div className="reaction-count">
																<img
																	src={`/content/img/reactions/bfo-${ReactionIcon[icon]}.svg`}
																/>
																<ReactCSSTransitionGroup
																	transitionName="fade"
																	transitionEnterTimeout={500}
																	transitionLeaveTimeout={300}
																>
																	{isLoading ? (
																		<i className="fal fa-spin fa-spinner-third" />
																	) : (
																		<span key={reactionNumbers[icon]}>
																			{reactionNumbers[icon] || "0"}
																		</span>
																	)}
																</ReactCSSTransitionGroup>
															</div>
														);
													}
												})}
											</div>
										</div>
									</div>
								)}
								{!!data?.linkClicks && data?.linkClicks.length > 0 && (
									<h4 className="well-subtitle">Link Clicks</h4>
								)}
								{data?.linkClicks?.map(linkClick => (
									<div className="pill-row">
										<div className="pill pill-12">
											<h5>{linkClick.label}</h5>
											<ReactCSSTransitionGroup
												transitionName="fade"
												transitionEnterTimeout={500}
												transitionLeaveTimeout={300}
											>
												<h3>{linkClick.value}</h3>
											</ReactCSSTransitionGroup>
										</div>
									</div>
								))}
								<h4 className="well-subtitle">Device Breakdown</h4>
								<div className="pill-row">
									<div className="pill pill-4">
										<h5>Desktop</h5>
										<ReactCSSTransitionGroup
											transitionName="fade"
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}
										>
											{isLoading ? (
												<i className="fal fa-spin fa-spinner-third" />
											) : (
												<h3>{`${
													getDeviceCategoryPercentage(data?.deviceCategories || {})["desktop"]
												}%`}</h3>
											)}
										</ReactCSSTransitionGroup>
									</div>
									<div className="pill pill-4">
										<h5>Tablet</h5>
										<ReactCSSTransitionGroup
											transitionName="fade"
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}
										>
											{isLoading ? (
												<i className="fal fa-spin fa-spinner-third" />
											) : (
												<h3>{`${
													getDeviceCategoryPercentage(data?.deviceCategories || {})["tablet"]
												}%`}</h3>
											)}
										</ReactCSSTransitionGroup>
									</div>
									<div className="pill pill-4">
										<h5>Mobile</h5>
										<ReactCSSTransitionGroup
											transitionName="fade"
											transitionEnterTimeout={500}
											transitionLeaveTimeout={300}
										>
											{isLoading ? (
												<i className="fal fa-spin fa-spinner-third" />
											) : (
												<h3>{`${
													getDeviceCategoryPercentage(data?.deviceCategories || {})["mobile"]
												}%`}</h3>
											)}
										</ReactCSSTransitionGroup>
									</div>
								</div>
								{!!data?.trafficSources && data?.trafficSources.length > 0 && (
									<h4 className="well-subtitle">Traffic Sources</h4>
								)}
								{data?.trafficSources?.map(source => (
									<div className="pill-row">
										<div className="pill pill-12">
											<h5>
												{source.label}
												<br />
												{source.isSocialSource ? "Social Media" : "Website"}
											</h5>
											<ReactCSSTransitionGroup
												transitionName="fade"
												transitionEnterTimeout={500}
												transitionLeaveTimeout={300}
											>
												<h3>{source.value}</h3>
											</ReactCSSTransitionGroup>
										</div>
									</div>
								))}
							</Well>
							{data?.paywallCsv && (
								<>
									<PageTitle>Exports</PageTitle>
									<Well>
										<h4 className="well-subtitle">
											Viewer Access Report
											<a
												href={data?.paywallCsv}
												className="float-right cursor-pointer btn-icon"
												title="Viewer Access Report"
												onClick={downloadFile}
												download={`${dashboard.clientData?.name}-${contentData?.name}-viewer-access.csv`}
											>
												<i className="far fa-file-export"></i>
											</a>
										</h4>
									</Well>
								</>
							)}
						</>
					)}
					{!isEmpty(data?.interactions) && currentPanel === "interactions" && (
						<>
							<PageTitle>Interactions</PageTitle>
							<Well message="Analytics may be delayed by up to 24 hours for some stats.">
								<h4 className="well-subtitle">
									Interactions
									{data?.answerData && (
										<a
											href={data?.answerData}
											className="float-right cursor-pointer btn-icon"
											title="Export Interaction Results"
											onClick={downloadFile}
											download={`${dashboard.clientData?.name}-${contentData?.name}-interactions.csv`}
										>
											<i className="far fa-file-export"></i>
										</a>
									)}
								</h4>
								{data?.interactions &&
									Object.values(data.interactions).map(interaction => {
										return (
											<div className="pill-row">
												<div className="pill pill-12">
													<h5>
														<div>{interaction.interactionTitle}</div>
														<div>
															{interaction.interactionText
																.replace(/(<([^>]+)>)/gi, "")
																.replace("&nbsp;", " ")}
														</div>
													</h5>
													{Object.values(interaction.responses).map(response => {
														return (
															<div>
																<ReactCSSTransitionGroup
																	transitionName="fade"
																	transitionEnterTimeout={500}
																	transitionLeaveTimeout={300}
																>
																	<h4
																		style={{ float: "right", margin: "0 0 0 10px" }}
																	>
																		{interaction.answers[response.id]}
																	</h4>
																</ReactCSSTransitionGroup>
																<h4>{response.responseText}</h4>
															</div>
														);
													})}
													<div>
														<ReactCSSTransitionGroup
															transitionName="fade"
															transitionEnterTimeout={500}
															transitionLeaveTimeout={300}
														>
															<h4 style={{ float: "right", margin: "0 0 0 10px" }}>
																{interaction.answers["null"]}
															</h4>
														</ReactCSSTransitionGroup>
														<h4>Dismiss</h4>
													</div>
												</div>
											</div>
										);
									})}
							</Well>
						</>
					)}
					{contentData?.chat.enabled && currentPanel === "chat" && (
						<>
							<Row>
								<Col lg={hostChats.length ? 6 : 12} md={12} className="pl-0">
									<PageTitle>Chat</PageTitle>
									<Well>
										<div className="pill-row">
											<div className="pill pill-4">
												<h5>Admins in Chat</h5>
												<ReactCSSTransitionGroup
													transitionName="fade"
													transitionEnterTimeout={500}
													transitionLeaveTimeout={300}
												>
													{isLoading ? (
														<i className="fal fa-spin fa-spinner-third" />
													) : (
														<h3>{data?.chatHosts || "0"}</h3>
													)}
												</ReactCSSTransitionGroup>
											</div>
											<div className="pill pill-4">
												<h5>Viewers in Chat</h5>
												<ReactCSSTransitionGroup
													transitionName="fade"
													transitionEnterTimeout={500}
													transitionLeaveTimeout={300}
												>
													{isLoading ? (
														<i className="fal fa-spin fa-spinner-third" />
													) : (
														<h3>{data?.chatUsers || "0"}</h3>
													)}
												</ReactCSSTransitionGroup>
											</div>
											<div className="pill pill-4">
												<h5>Chat Messages</h5>
												<ReactCSSTransitionGroup
													transitionName="fade"
													transitionEnterTimeout={500}
													transitionLeaveTimeout={300}
												>
													{isLoading ? (
														<i className="fal fa-spin fa-spinner-third" />
													) : (
														<h3>{data?.chatsSent || "0"}</h3>
													)}
												</ReactCSSTransitionGroup>
											</div>
										</div>
									</Well>
								</Col>
								<Col lg={hostChats.length ? 6 : 12} md={12} className="pr-0">
									{(data?.chatDataCsv || data?.chatTranscriptCsv) && (
										<>
											<PageTitle>Exports</PageTitle>
											<Well>
												{data?.chatDataCsv !== "" && contentData?.chat.enabled && (
													<h4 className="well-subtitle">
														Chat Overview Report
														<a
															href={data?.chatDataCsv}
															className="float-right cursor-pointer btn-icon"
															title="Chat Overview Report"
															onClick={downloadFile}
															download={`${dashboard.clientData?.name}-${contentData?.name}-chat-overview.csv`}
														>
															<i className="far fa-file-export"></i>
														</a>
													</h4>
												)}
												{data?.chatTranscriptCsv !== "" && contentData?.chat.enabled && (
													<h4 className="well-subtitle">
														Chat Transcript
														<a
															href={data?.chatTranscriptCsv}
															className="float-right cursor-pointer btn-icon"
															title="Chat Transcript"
															onClick={downloadFile}
															download={`${dashboard.clientData?.name}-${contentData?.name}-chat-transcript.csv`}
														>
															<i className="far fa-file-export"></i>
														</a>
													</h4>
												)}
											</Well>
										</>
									)}
								</Col>
							</Row>
							{!!hostChats.length && contentData && (
								<>
									<HostChats hostChats={hostChats} contentId={contentId} contentData={contentData} />
								</>
							)}
						</>
					)}
					{(isGlobalAdmin || contentData?.contentType !== ContentType.onDemand) && (
						<Button className="btn-block text-center mt-1" onClick={refreshAnalytics}>
							Generate Analytics Report
						</Button>
					)}
					{/* @ts-ignore */}
					{contentData?.runAnalytics === true && data?.cached === true && (
						<small className="white">
							Previously Generated {moment(data?.timestamp).format("dddd, MMMM D, YYYY h:mm A")}
						</small>
					)}
				</>
			)}
			{analyticsStatus === AnalyticsProcessed.processing && (
				<>
					<Well>Your analytics are processing.</Well>
					{isGlobalAdmin && (
						<Button className="btn-block text-center mt-1" onClick={refreshAnalytics}>
							Restart Analytics
						</Button>
					)}
				</>
			)}
			{analyticsStatus === AnalyticsProcessed.unprocessed && (
				<>
					{contentData?.contentType === ContentType.onDemand ? (
						<Well>Your analytics will begin processing soon.</Well>
					) : (
						<Well>Your analytics will be available shortly after your content ends.</Well>
					)}
				</>
			)}
		</SectionedPageContainer>
	);
};

export default ContentAnalytics;
